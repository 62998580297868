/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { injectable, inject } from 'inversify';
import { makeObservable, observable, action } from 'mobx';
import { Auth, Loader, Snackbars } from '@stores/domains';
import { withAuth } from '@stores/domains/Auth/decorators';
import { loadable } from '@stores/domains/Loader/decorators';
import { TYPES } from '@stores/types';
import { ErrorCodes, StatusCode } from '@utils/constants';
import { extractAxiosErrorResponse, isErrorCodeMatch } from '@utils/network';
import { ErrorCodeMessages, SuccessCodeMessages } from '@utils/translations';
import { LoaderName } from './constants';
import { Requests } from './Requests';

@injectable()
export class GameAccounts {
  @inject(TYPES.Auth) auth: Auth;
  @inject(TYPES.Loader) loader: Loader;
  @inject(TYPES.Snackbars) snackbars: Snackbars;

  skipCharactersCheck = false;

  constructor() {
    makeObservable(this, {
      skipCharactersCheck: observable,
      setSkipCharactersCheck: action
    });
  }

  setSkipCharactersCheck(skipCharactersCheck: boolean): void {
    this.skipCharactersCheck = skipCharactersCheck;
  }

  @withAuth
  @loadable(LoaderName.CreateAccount)
  async createAccount(clientLanguageCode: string, receiveEmails: boolean): Promise<void> {
    try {
      await Requests.createAccount({ clientLanguageCode, receiveEmails });
      this.snackbars.enqueueDefaultSuccess(SuccessCodeMessages.AddGameAccount);
    } catch (e) {
      const extractedError = extractAxiosErrorResponse(e);

      if (isErrorCodeMatch(extractedError, ErrorCodes.TooManyAccounts)) {
        this.snackbars.enqueueDefaultError(ErrorCodeMessages.LimitOfLinkedAccounts);
      } else if (extractedError.status >= StatusCode.BadRequest) {
        this.snackbars.enqueueDefaultError(ErrorCodeMessages.AddGameAccount);
      } else {
        throw e;
      }
    }
  }
}
