/*
 * Copyright 1999-2024 Jagex Ltd.
 */
// eslint-disable-next-line max-len
import { EmailOwnershipCodeFields } from '@components/dialogs/ImportGameAccountDialog/steps/EmailVerificationStep/models';
import { EligibleMigrationCandidate } from '@stores/domains/AccountMigration/types';

export type CurrentUser = {
  email: string;
  displayName: string;
  suffix: string;
  id: string;
};

export interface UserLoggedInState {
  profileData: CurrentUser;
  marketingPreferences: {
    receiveEmails: boolean;
  };
}

export type UserState = UserLoggedInState | null;

export type UserCurrentResponse = CurrentUser;

export enum UserType {
  Jagex = 'JAGEX',
  FirstParty = 'FIRST_PARTY'
}
export interface UserTypeInformation {
  userType: UserType;
  newlyCreated: boolean;
}

export type UserMarketingPreferencesResponse = {
  receiveEmails: boolean;
};

export type EmailVerificationSubmitData = EmailOwnershipCodeFields;

export type UserEmailStatus = {
  recentlyVerified: boolean;
};

export enum VerificationState {
  AwaitingCode = 'AWAITING_CODE',
  Verified = 'VERIFIED'
}
export interface CodeVerificationState {
  id: string;
  state: VerificationState;
}

export enum NewcomerActionVariant {
  Import = 'IMPORT',
  Create = 'CREATE',
  Skip = 'SKIP'
}
export type NewcomerImportAction = {
  type: NewcomerActionVariant.Import;
  eligibleCandidate: EligibleMigrationCandidate;
};
export type NewcomerCreateAction = {
  type: NewcomerActionVariant.Create;
};
export type NewcomerSkipAction = {
  type: NewcomerActionVariant.Skip;
};
export type NewcomerAction = NewcomerImportAction | NewcomerCreateAction | NewcomerSkipAction;
